import React from 'react';
import { graphql } from 'gatsby';

import {
    layout,
    occasions,
    title,
    subtitle,
    extraInfo,
    subtitle__bold,
    wrapper,
} from './label-occasions-page.module.scss';

import { TCommonTemplateData } from '../models/common-template-data.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ILabelOccasion } from '../models/label-occasion.model';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import LabelOccasion from '../components/organisms/label-occasion';

interface ILabelOccasionsPage {
    readonly data: {
        allStrapiOccasion: IQueryAllResult<ILabelOccasion>;
    } & TCommonTemplateData;
}

const LabelOccasionsPage: React.FC<ILabelOccasionsPage> = ({ data }) => {
    const { t } = useI18next();
    const occasionList = getNodes(data.allStrapiOccasion).sort((a, b) =>
        a.title > b.title ? -1 : 1
    );

    return (
        <MainLayout
            className={layout}
            showRecipesSlider={false}
            includeHeaderPadding={false}
            page={data.page}
        >
            <div className={occasions}>
                <h1 className={title}>{t('label.occasions.title')}</h1>
                <h2 className={subtitle}>
                    <span className={subtitle__bold}>{t('label.occasions.subtitle.bold')}</span>
                    <span>, {t('label.occasions.subtitle.rest')}</span>
                </h2>
                <div className={wrapper}>
                    {occasionList.map((occasion) => (
                        <LabelOccasion key={`occasion-${occasion.key}`} occasion={occasion} />
                    ))}
                </div>
                <div>
                    <p className={extraInfo}>*{t('label.creator.description')}</p>
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allStrapiOccasion(filter: { locale: { eq: $language } }) {
            edges {
                node {
                    title
                    key
                    mainPhoto {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, quality: 99)
                            }
                        }
                    }
                    hoverPhoto {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default LabelOccasionsPage;
