import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    name,
    button,
    imageWrapper,
    image,
    imageMain,
    imageHover,
} from './label-occasion.module.scss';

import pagesContext from '../../config/pages-context';
import { ILabelOccasion, TOccasionType } from '../../models/label-occasion.model';

interface ILabelOccasionProps {
    occasion: ILabelOccasion;
}

const LabelOccasion: React.FC<ILabelOccasionProps> = ({ occasion }) => {
    const { t } = useI18next();
    const { mainPhoto, hoverPhoto, key, title } = occasion;
    const mainImage = getImage(mainPhoto[0].localFile);
    const hoverImage = getImage(hoverPhoto[0].localFile);
    const pathname = getPresentationPageUrl(key);

    return (
        <Link to={pathname} className={container}>
            <div className={imageWrapper}>
                {mainImage && (
                    <GatsbyImage className={`${image} ${imageMain}`} alt={''} image={mainImage} />
                )}
                {hoverImage && (
                    <GatsbyImage
                        className={`${image} ${imageHover}`}
                        alt={''}
                        image={hoverImage}
                        loading={'eager'}
                    />
                )}
            </div>
            <p className={name}>{title}</p>
            <button className={button}>{t('label.occasions.button')}</button>
        </Link>
    );
};

function getPresentationPageUrl(key: TOccasionType) {
    switch (key) {
        case 'wedding':
            return `/${pagesContext.labelPresentation.slug}`;
        case 'anniversary':
            return `/${pagesContext.anniversaryLabelPresentation.slug}`;
        case 'birthday':
            return `/${pagesContext.birthdayLabelPresentation.slug}`;
        default:
            return '/';
    }
}

export default LabelOccasion;
